





























































































































import { Component, Vue } from "vue-property-decorator";
import modeloModule from "@/store/modules/modelo-module";
import { JwtService } from "@/shared/services/JwtService";
import { UtilsString } from "@/utils/utils-string";
import { UtilsNotify } from "@/utils/utils-notify";
import { modeloAll } from "@/shared/dtos/platillas-modelos/modelo-all";
import { RouterNames } from "@/router/routernames";
import { UtilsEncript } from "@/utils/utils-encript";
import { singleOrDoubleRowClick } from "./dblclickrow";
import { buttonOptions } from "@/components/Buttons/buttonOptions";
import { paciente } from "@/shared/dtos/paciente-dto";
import pacienteModule from "@/store/modules/paciente-module";
@Component({
  components: {
    PacientesSeleccion: () =>
      import("@/views/pacientes/pacientes-seleccion.vue"),
    addplatilla: () => import("@/views/plantillas/add-plantilla.vue"),
  },
})
export default class plantillaslista extends Vue {
  public search: string = "";
  public mi_id: string = "";
  public platilla_seleccionada: number = -1;
  public show_dialog_paciente: boolean = false;
  public pacienteSeleccionado: paciente = new paciente();
  public show_dialog_more_options: boolean = false;
  public show_dialog_add_plantilla: boolean = false;
  public actualizar = false;

  public created() {
    modeloModule.getmodelos();
    this.mi_id = JwtService.jwtDecode().nameid;
  }
  public get modelos() {
    return modeloModule.modelos;
  }

  public get Opciones_a_realizar() {
    let opts: buttonOptions[] = [];
    opts.push(
      new buttonOptions({
        id: 1,
        title: "Duplicar plantilla",
        icono: "",
        EventMethod: this.duplicar_platilla,
      })
    );
    opts.push(
      new buttonOptions({
        id: 2,
        title: "Asignar plantilla a paciente",
        icono: "",
        EventMethod: this.asignar_platilla,
      })
    );

    opts.push(
      new buttonOptions({
        id: 4,
        title: "Editar nombre",
        icono: "",
        EventMethod: this.editar_nombre_plantilla,
      })
    );
    return opts;
  }

  public get headers() {
    return [
      {
        text: "Nombre",
        value: "nombre_modelo",
      },
      {
        text: "Descripción",
        value: "descripcion_modelo",
      },
      {
        text: "Energía",
        value: "energia",
      },
      { text: "Glúcidos", value: "porcen_hc" },
      { text: "Grasas", value: "porcen_gr" },
      { text: "Proteínas", value: "porcen_pr" },
      { text: "", value: "acciones" },
    ];
  }
  public eliminar(item: any) {
    modeloModule
      .eliminarmodelo(item)
      .then(() =>
        UtilsNotify.NotificacionSuccess(
          "Plantilla",
          "La plantilla se ha borrado correctamente"
        )
      );
  }
  public editar(item: modeloAll) {
    this.$router.push({
      name: RouterNames.dieta,
      query: {
        id_dieta: UtilsEncript.Encriptar(item.id_dieta.toString()),
        id_paciente: UtilsEncript.Encriptar("-1"),
      },
    });
  }
  public dblclick(item: any, singleClickFunc: any, doubleClickFunc: any) {
    singleOrDoubleRowClick(item, singleClickFunc, doubleClickFunc);
  }
  public Compare(id1: string, id2: string) {
    if (UtilsString.ValueOf(id1) === UtilsString.ValueOf(id2)) {
      return true;
    }
    return false;
  }
  public duplicar_platilla() {
    modeloModule.Clonar_modelo(this.platilla_seleccionada).then(() => {
      UtilsNotify.NotificacionSuccess(
        "Plantilla clonada",
        "Se ha clonado correctamente la plantilla"
      );
      this.show_dialog_more_options = false;
      modeloModule.getmodelos();
    });
  }
  public asignar_platilla() {
    if (pacienteModule.pacientes.length === 0) {
      pacienteModule.getpacientes();
    }
    this.show_dialog_paciente = true;
  }
  public mas_opciones(item: modeloAll) {
    this.platilla_seleccionada = item.id;
    this.show_dialog_more_options = true;
  }
  public add_plantilla() {
    this.show_dialog_add_plantilla = true;
    this.actualizar = false;
  }
  public editar_nombre_plantilla() {
    this.actualizar = true;
    this.show_dialog_more_options = false;
    this.show_dialog_add_plantilla = true;
  }
  public AceptarAsignacion() {
    modeloModule
      .Asignar_modelo_a({
        id_modelo: this.platilla_seleccionada,
        id_paciente: this.pacienteSeleccionado.numero,
      })
      .then(() => {
        UtilsNotify.NotificacionSuccess(
          "Plantilla asignada",
          "Se ha asignado correctamente la plantilla"
        );
        this.show_dialog_paciente = false;
        this.show_dialog_more_options = false;
      });
  }
}
