var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vs-card',{staticClass:"mt-5"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.modelos,"search":_vm.search},on:{"click:row":function($event){_vm.dblclick($event, function () {}, _vm.editar)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Plantillas de dietas")]),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.add_plantilla()}}},[_vm._v("Crear plantilla"),_c('v-icon',[_vm._v("add")])],1),_c('v-text-field',{staticClass:"ml-4",attrs:{"append-icon":"search","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" Las plantillas se suelen utilizar para asignar al paciente una dieta de manera rápida. Seguidamente la dieta asígnada se puede modificar para personalizarla. ")]},proxy:true},{key:"item.porcen_hc",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.porcen_hc ? item.porcen_hc : 0)+"%")]),_c('v-progress-linear',{attrs:{"color":"#EA9F77"},model:{value:(item.porcen_hc),callback:function ($$v) {_vm.$set(item, "porcen_hc", $$v)},expression:"item.porcen_hc"}})]}},{key:"item.porcen_pr",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.porcen_pr ? item.porcen_pr : 0)+"%")]),_c('v-progress-linear',{attrs:{"color":"#66CCB7"},model:{value:(item.porcen_pr),callback:function ($$v) {_vm.$set(item, "porcen_pr", $$v)},expression:"item.porcen_pr"}})]}},{key:"item.porcen_gr",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.porcen_gr ? item.porcen_gr : 0)+"%")]),_c('v-progress-linear',{attrs:{"color":"#DB4965"},model:{value:(item.porcen_gr),callback:function ($$v) {_vm.$set(item, "porcen_gr", $$v)},expression:"item.porcen_gr"}})]}},{key:"item.acciones",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","icon":"","color":"green darken-2"},on:{"click":function($event){return _vm.mas_opciones(item)}}},[_c('v-icon',[_vm._v("more_vert")])],1),(
          _vm.Compare(item.id_nutricionista_propietario, _vm.mi_id) && !item.sistema
        )?_c('v-btn',{attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.editar(item)}}},[_c('v-icon',[_vm._v("edit")])],1):_vm._e(),(
          _vm.Compare(item.id_nutricionista_propietario, _vm.mi_id) && !item.sistema
        )?_c('v-btn',{attrs:{"x-small":"","icon":"","color":"red darken-2"},on:{"click":function($event){return _vm.eliminar(item)}}},[_c('v-icon',[_vm._v("delete")])],1):_vm._e()]}}])}),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.show_dialog_more_options),callback:function ($$v) {_vm.show_dialog_more_options=$$v},expression:"show_dialog_more_options"}},[_c('v-card',{staticClass:"text-center"},[_c('v-card-title',{},[_vm._v("Acciones")]),_c('v-card-subtitle',{staticClass:"subtitle-1"},[_vm._v("Selecciona la opción")]),_c('v-divider'),_c('v-card-text',{staticClass:"text-center"},[_c('v-list',_vm._l((_vm.Opciones_a_realizar),function(item,index){return _c('v-list-item',{key:index,on:{"click":item.EventMethod}},[(item.icono)?_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icono))])],1):_vm._e(),_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.show_dialog_paciente),callback:function ($$v) {_vm.show_dialog_paciente=$$v},expression:"show_dialog_paciente"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Seleccione el paciente")]),_c('v-card-text',{staticClass:"text-center"},[_c('PacientesSeleccion',{attrs:{"paciente":_vm.pacienteSeleccionado},on:{"update:paciente":function($event){_vm.pacienteSeleccionado=$event}}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){return _vm.AceptarAsignacion()}}},[_vm._v("Aceptar")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.show_dialog_add_plantilla),callback:function ($$v) {_vm.show_dialog_add_plantilla=$$v},expression:"show_dialog_add_plantilla"}},[(_vm.show_dialog_add_plantilla)?_c('addplatilla',{attrs:{"id":_vm.platilla_seleccionada,"update":_vm.actualizar},on:{"close":function($event){_vm.show_dialog_add_plantilla = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }